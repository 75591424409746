.connection-item-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.connection-item-text {
    color: inherit;
    text-decoration: none;
    font-size: inherit;
}