@import url('https://fonts.googleapis.com/css2?family=Nunito&family=Pacifico&family=Passions+Conflict&display=swap');
@import url('https://fonts.googleapis.com/css?family=Marck Script&display=swap');

.App {
  text-align: center;
  font-family: 'Nunito';
  font-size: calc(10px + 2vmin);
}


.mainBody {
  background-color: #F7FDFF;
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #282c34;
}

.innerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.upperContainer{
  width:100vw;
  height: 70vh;
  padding-bottom: 5vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
}

.lowerContainer {
  width:70vw;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  background-color: #F7FDFF;
  border-radius: 15px;
  padding-left:2vw;
  padding-right:2vw;
  padding-top:1vh;
  padding-bottom:1vh;
}

.mainImageContainer {  
  background-image: url('../public/house.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}

.titleContainer {
  display: flex;
  justify-content: center;
}

.title {
  font-weight: 600;
  font-style: bold;
  font-size: calc(125px + 2vmin);
  font-family: 'Marck Script';
  color:aliceblue;
}

.subtitleContainer {
  border: 2px solid aliceblue;
  padding-left: 1vw;
  padding-right: 1vw;
  border-radius: 15px;
  border-style: double;
}

.subtitle {
  font-weight: 600;
  font-size: calc(6px + 2vmin);
  color:aliceblue;
}

.bodyfont {
  font-weight: 500;
  font-style: normal;
  font-size: calc(7px + 2vmin);
}

.maintext {
  padding-top: 1vh;  
  width:60%
}

.descriptionItemsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
}

.drHortonContainer {
  padding-top: 5vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.drhLogo {
  cursor: pointer;
  width: 100%;
  object-fit: contain;
}

.drhLogoContainer {
  width: 40%;
  overflow: hidden;
}

.drTextContainer {
  width: 60%;
}

.divider {
  border-top: #282c34 3px solid;
  width: 90%;
}

.dividerContainer {
  padding: 1em;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
}

.contactContainer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  padding-top: 5vh;
  padding-bottom: 5vh;
}

.divider {
  min-height: 3px;
  margin-top: 2vh;
  margin-bottom: 1vh;
  border-top: 3px solid #59B5AD;
}

.cta {
}

.contactInfoCard {
  padding-right: 2vw;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
}

.lowerlower {
  margin-top: 1vh;
  padding-top: 1vh;
}

@media only screen and (max-width: 600px) {
  .title {
    font-size: calc(65px + 2vmin);
  }
  .lowerContainer {
    width: 85%
  }
  .contactContainer {
    flex-direction: column;
  }
  .contactInfoCard{
    padding: none;
    width: 100%;
    align-items: center;
    padding-bottom: 1em;
  }
}